.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: black;
  position: relative; /* Pour positionner les étincelles */
}

.animated-text {
  font-size: 6rem; /* Taille du texte */
  font-family: "ChodaFont"; /* Police personnalisée */
  color: #e94789; /* Couleur du texte principal */
  text-shadow: 3px 3px #fcdd03; /* Ombre jaune décalée de 3px en horizontal et vertical */
  position: relative;
  z-index: 2; /* Assure que le texte est au-dessus du sous-titre */
}

/* Conteneur pour le sous-titre */
.subtitle-container {
  visibility: hidden; /* Initialement invisible */
  min-height: 1.5em; /* Réserve de l'espace pour le sous-titre */
  width: 100%; /* Assure que le conteneur prend toute la largeur */
  display: flex; /* Utilise flex pour centrer le contenu */
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
}

.under-construction {
  font-family: 'Courier New', monospace;
  transform: rotate(-5deg); /* Inclinaison négligente */
  font-size: 1.5rem;
  color: white;
  margin-top: 20px; /* Espacement avec le texte principal */
}

@font-face {
  font-family: 'ChodaFont';
  src: url('chodafont-webfont.woff2') format('woff2'),
       url('chodafont-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


/* Requêtes de médias pour les écrans plus petits (téléphones) */
@media (max-width: 600px) { /* Ajustez ce breakpoint selon vos besoins */
  .animated-text {
    font-size: 4.5rem; /* Taille plus petite du texte pour les écrans de téléphone */
    text-shadow: 2.2px 2.2px #fcdd03;
  }
}